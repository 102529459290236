export default [
  {
    path: '/main',
    name: 'home',
    component: () => import('@/views/main/MainView.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },

  {
    path: '/menu/:id',
    name: 'open-purpose',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      // pageTitle: '인사말',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'course-curriculum',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      // pageTitle: '교육목적',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'organization-chart',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      // pageTitle: '교육특전',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/pros',
    name: 'director-chart',
    component: () => import('@/views/menu/Profs.vue'),
    meta: {
      pageTitle: '교수진',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'committee-member',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      // pageTitle: '교과내용',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'committee-summary',
    component: () => import('@/views/menu/MenuView.vue'),
    // meta: {
    //   // pageTitle: '운영개요',
    //   resource: 'ACL',
    //   action: 'read',
    // },
  },
  {
    path: '/menu/:id',
    name: 'admission-info',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      // pageTitle: '입학정보',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'location-map',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      // pageTitle: '찾아오시는길',
      resource: 'ACL',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- 총동문회 소개
  // *===============================================---*
  {
    path: '/menu/:id',
    name: 'chairman-greeting',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      pageTitle: '총동문회장 인사말',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'presidency-list',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      pageTitle: '역대 회장단',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'association-chart',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      pageTitle: '26대 임원진 명단',
      resource: 'ACL',
      action: 'read',
    },
  },
  // {
  //   path: '/menu/:id',
  //   name: 'association-presidency',
  //   component: () => import('@/views/menu/MenuView.vue'),
  //   meta: {
  //     pageTitle: '총동문회 회장단',
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/post/list/:id',
  //   name: 'gens-presidency',
  //   component: () => import('@/views/post/BlogList.vue'),
  //   meta: {
  //     pageTitle: '기수별 회장단',
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  {
    path: '/rules',
    name: 'association-rule',
    component: () => import('@/views/menu/Rules.vue'),
    meta: {
      pageTitle: '총동문회 회칙',
      resource: 'ACL',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- 행사소식
  // *===============================================---*
  {
    path: '/post/list/:id',
    name: 'event-schedule',
    component: () => import('@/views/post/BlogList.vue'),
    meta: {
      pageTitle: '행사일정',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/post/list/:id',
    name: 'golf-meeting',
    component: () => import('@/views/post/BlogList.vue'),
    meta: {
      pageTitle: '골프회',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/post/list/:id',
    name: 'climbing-meeting',
    component: () => import('@/views/post/BlogList.vue'),
    meta: {
      pageTitle: '등산회',
      resource: 'ACL',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- 원우수첩
  // *===============================================---*
  {
    path: '/user/books',
    name: 'friends-book',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: '동문검색',
      resource: 'ACL',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- 원우회 소식
  // *===============================================---*
  {
    path: '/post/list/:id',
    name: 'announcement',
    component: () => import('@/views/post/BlogList.vue'),
    meta: {
      pageTitle: '공지사항',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/post/list/:id',
    name: 'friends-news',
    component: () => import('@/views/post/BlogList.vue'),
    meta: {
      pageTitle: '원우소식',
      resource: 'ACL',
      action: 'read',
    },
  },
  // {
  //   path: '/post/list/:id',
  //   name: 'freeboard',
  //   component: () => import('@/views/post/BlogList.vue'),
  //   meta: {
  //     pageTitle: '자유게시판',
  //     resource: 'ACL',
  //     action: 'read',
  //   },
  // },
  {
    path: '/post/list/:id',
    name: 'event-gallery',
    component: () => import('@/views/post/BlogList.vue'),
    meta: {
      pageTitle: '행사갤러리',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/calendar/',
    name: 'event-schedule',
    component: () => import('@/views/post/BlogList.vue'),
    meta: {
      pageTitle: '행사일정',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/:id',
    name: 'book-usage',
    component: () => import('@/views/menu/MenuView.vue'),
    meta: {
      pageTitle: '원우수첩활용법',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/banner/list',
    name: 'banner-manage',
    component: () => import('@/views/banner/BannerView.vue'),
    meta: {
      pageTitle: '광고관리',
    },
  },
  {
    path: '/banner/register',
    name: 'banner-register',
    component: () => import('@/views/banner/BannerCreate.vue'),
    meta: {
      pageTitle: '광고관리',
    },
  },
  {
    path: '/banner/:id',
    name: 'banner-edit',
    component: () => import('@/views/banner/BannerEdit.vue'),
    meta: {
      pageTitle: '광고관리',
    },
  },
];

import Vue from 'vue';

// axios
import axios from 'axios';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'http://121.78.147.99:5044/api',
  // baseURL: 'http://127.0.0.1:5044/api',
  // timeout: 1000,
  headers: { 'X-BranchInfoId': 3 },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;

export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: '내정보',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/profile',
    name: 'pages-profile',
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/post/:postid',
    name: 'post-detail',
    component: () => import('@/views/post/BlogDetail.vue'),
    meta: {
      pageTitle: ' ',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/post/edit/:menuid/:postid',
    name: 'post-edit',
    component: () => import('@/views/post/BlogEdit.vue'),
    meta: {
      pageTitle: ' ',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/post/create/:menuid',
    name: 'post-create',
    component: () => import('@/views/post/BlogCreate.vue'),
    meta: {
      pageTitle: ' ',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/menu/edit/:menuid/:postid',
    name: 'menu-edit',
    component: () => import('@/views/menu/MenuEdit.vue'),
    meta: {
      pageTitle: ' ',
      resource: 'ACL',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- MAMAGER
  // *===============================================---*
  {
    path: '/admin/users/list',
    name: 'admin-users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Profile',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/admin/users/view/:id',
    name: 'admin-users-view',
    component: () => import('@/views/user/users-view/UsersView.vue'),
    meta: {
      pageTitle: 'Profile',
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: '/admin/users/edit/:id',
    name: 'admin-users-edit',
    component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Profile',
      resource: 'ACL',
      action: 'read',
    },
  },
];
